import axios from "axios";
import { useEffect, useState } from "react";
import appConfig from "../config.json";
import "../views/events.css";
import {
    Space,
    Table,
    Button,
    Modal,
    Input,
    Form,
    message,
    Image,
    Popconfirm,
} from "antd";
import "../bootstrap-grid.css";
import { UserAddOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Header from "../components/Header";
import Loader from "../components/Loader";

function Consejo() {
    const [consejoData, setConsejoData] = useState([]);
    const [isModalNewOpen, setIsModalNewOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [consejoToEdit, setConsejoToEdit] = useState(null);
    const [isActiveLoader, setIsActiveLoader] = useState(false);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
        },
        {
            title: "Puesto",
            dataIndex: "puesto",
            key: "puesto"
        },
        {
            title: "Imagen",
            key: "imagen",
            render: (_, record) => {
                let imagen = record.imagen !== null || record.imagen !== "" ? record.imagen: null;
                return (<>
                    {imagen !== null ? <Image
                        
                        width={80}
                        src={appConfig.API_URL + "consejo/" + imagen}
                    /> : "Imagen no disponible"}
                </>);
            }
        },
        {
            title: "Opciones",
            key: "opciones",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Button
                            onClick={(e) => {
                                editModalHandle(record);
                            }}
                            type="text"
                        >
                            Editar
                        </Button>
                        {record.activo === 1 ? (
                            <Popconfirm
                                title="Desactivar  miembro"
                                description="¿Está seguro de desactivar a este evento?"
                                onConfirm={(e) => {
                                    disableConsejo(record.id);
                                }}
                                onCancel={() => { }}
                                okText="Sí, desactivar"
                                cancelText="Cancelar"
                            >
                                <Button type="text" danger>
                                    Desactivar
                                </Button>
                            </Popconfirm>
                        ) : (
                            <Button
                                onClick={(e) => {
                                    enableConsejo(record.id);
                                }}
                                type="text"
                            >
                                Activar
                            </Button>
                        )}
                    </Space>
                );
            },
        }
    ];

    const parseDate = (date) => {
        dayjs.extend(customParseFormat);
        return dayjs(date);
    }


    const newModalHandle = () => {
        let newRevista = {
            nombre: "",
            puesto: ""
        };
        setConsejoToEdit(newRevista);
        setIsModalNewOpen(true);
    };

    const editModalHandle = (recortToEdit) => {
        setConsejoToEdit(recortToEdit);
        setIsModalEditOpen(true);
    };

    const onChangeName = (event) => {
        setConsejoToEdit({ ...consejoToEdit, nombre: event.target.value });
    };

    const onChangePuesto = (event) => {
        setConsejoToEdit({ ...consejoToEdit, puesto: event.target.value });
    };

    useEffect(() => {
        getConsejo();
    }, []);

    const getConsejo = () => {
        let token = localStorage.getItem("token");
        setIsActiveLoader(true);
        axios
            .get(appConfig.API_URL + "api/app/get-consejo", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                if (response.data.success) {
                    setConsejoData(response.data.data);
                }
                setIsActiveLoader(false);
            });
    };

    const saveNewConsejo = () => {
        setIsActiveLoader(true);
        let token = localStorage.getItem("token");
        axios
            .post(appConfig.API_URL + "api/app/add-consejo", consejoToEdit, {
                headers: {
                    Authorization: "Bearer " + token,
                },
                onUploadProgress: function (progress) {
                    console.log("progressObj", progress);
                }
            })
            .then((response) => {
                if (response.data.success) {
                    //setEventsData(response.data.data);
                    message.success("Miebro registrado correctamente");
                    getConsejo();
                    setIsModalNewOpen(false);
                    setIsActiveLoader(false);
                }
            });
    };
    const updateConsejo = () => {
        setIsActiveLoader(true);
        let token = localStorage.getItem("token");
        axios
            .post(appConfig.API_URL + "api/app/update-consejo", consejoToEdit, {
                headers: {
                    Authorization: "Bearer " + token,
                },
                onUploadProgress: function (progress) {
                    console.log("progressObj", progress);
                }
            })
            .then((response) => {
                if (response.data.success) {
                    //setEventsData(response.data.data);
                    message.success("Miebro registrado correctamente");
                    getConsejo();
                    setIsModalNewOpen(false);
                    setIsActiveLoader(false);
                }
            });
    };

    const disableConsejo = () => {

    }
    const enableConsejo = () => {

    }

    const onChangeNewImageConsejo = (event) => {

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);

        reader.onload = (e) => {
            console.log(e);
            if (e.total > 2097152) {
                message.error("La imagen seleccionada supera 1 MB de tamaño");
            } else {
                let sbstr = e.target.result.substring(11, 15);

                if (sbstr.includes("jpg") || sbstr.includes("jpeg")) {
                    setConsejoToEdit({
                        ...consejoToEdit,
                        imagen: e.target.result,
                    });
                } else {
                    message.error("Formato de archivo no admitido...");
                }
            }
        };

    };

    return (
        <>
            <Header></Header>
            <div className="contenedor">
                <div className="eventosContainer">
                    <div className="eventHeader">
                        <div className="sectionTitle">Miembros del consejo</div>
                    </div>
                    <Space size="small">
                        <Button
                            type="primary"
                            icon={<UserAddOutlined />}
                            onClick={newModalHandle}
                            style={{ marginBottom: 10 }}
                        >
                            Registrar miembro
                        </Button>
                    </Space>
                    <Table
                        columns={columns}
                        size="small"
                        sticky
                        dataSource={consejoData}
                    />
                </div>
            </div>

            <Modal
                title="Nuevo registro"
                open={isModalNewOpen}
                onOk={saveNewConsejo}
                onCancel={(e) => setIsModalNewOpen(false)}
                width={1000}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <div className="formContainer">
                    <Form layout="vertical">
                        <div className="row">
                            <div className="col-6">
                                <Form.Item label="Nombre">
                                    <Input
                                        placeholder=""
                                        value={consejoToEdit != null ? consejoToEdit.nombre: ""}
                                        onChange={onChangeName}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item label="Puesto">
                                    <Input
                                        placeholder=""
                                        value={consejoToEdit != null ? consejoToEdit.puesto: ""}
                                        onChange={onChangePuesto}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6" style={{ display: "flex", flexDirection: "row" }}>
                                <Form.Item label="Imagen de puesto en consejo">
                                    <input
                                        style={{ width: "135px" }}
                                        type="file"
                                        onChange={onChangeNewImageConsejo}
                                        accept="image/jpg"
                                    ></input>
                                </Form.Item>
                                <div>
                                    <img
                                        style={{
                                                display: (consejoToEdit?.imagen != null? "block":"none"),
                                                marginLeft: 10
                                        }}
                                        src={
                                            consejoToEdit?.imagen != null
                                                ? consejoToEdit.imagen
                                                : ""
                                        }
                                        alt="img"
                                        height={80}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>

            <Modal
                title="Editar miembro de consejo"
                open={isModalEditOpen}
                onOk={updateConsejo}
                onCancel={(e) => setIsModalEditOpen(false)}
                width={1000}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <div className="formContainer">
                    <Form layout="vertical">
                        <div className="row">
                            <div className="col-6">
                                <Form.Item label="Nombre">
                                    <Input
                                        placeholder=""
                                        value={consejoToEdit != null ? consejoToEdit.nombre : ""}
                                        onChange={onChangeName}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item label="Puesto">
                                    <Input
                                        placeholder=""
                                        value={consejoToEdit != null ? consejoToEdit.puesto : ""}
                                        onChange={onChangePuesto}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6" style={{ display: "flex", flexDirection: "row" }}>
                                <Form.Item label="Imagen de puesto en consejo">
                                    <input
                                        style={{ width: "135px" }}
                                        type="file"
                                        onChange={onChangeNewImageConsejo}
                                        accept="image/jpg"
                                    ></input>
                                </Form.Item>
                                <div>
                                    <img
                                        style={{
                                            display: (consejoToEdit?.imagen != null ? "block" : "none"),
                                            marginLeft: 10
                                        }}
                                        src={
                                            consejoToEdit?.imagen != null
                                                ? consejoToEdit.imagen.substring(0, 15).includes("data") ? consejoToEdit.imagen : appConfig.API_URL + "consejo/" + consejoToEdit.imagen
                                                : ""
                                        }
                                        alt="img"
                                        height={80}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Loader isActive={isActiveLoader}></Loader>
        </>
    );
}

export default Consejo;
