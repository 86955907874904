import axios from "axios";
import { useEffect, useState } from "react";
import appConfig from "../config.json";
import "../views/events.css";
import {
    Space,
    Table,
    Button,
    Modal,
    Input,
    Form,
    message,
    DatePicker,
    Popconfirm,
} from "antd";
import "../bootstrap-grid.css";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { UserAddOutlined } from "@ant-design/icons";
import Header from "../components/Header";
import Loader from "../components/Loader";

function Directorio() {
    const [directorioData, setDirectorioData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalNewOpen, setIsModalNewOpen] = useState(false);
    const [directorioDataToEdit, setDirectorioDataToEdit] = useState({});
    const [isActiveLoader, setIsActiveLoader] = useState(false);


    const columns = [
        {
            title: "Codigo",
            dataIndex: "codigo",
            key: "codigo",
            width:'7%'
        },
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
        },
        {
            title: "Apellidos",
            dataIndex: "apellidos",
            key: "apellidos",
        },
        {
            title: "Empresa",
            dataIndex: "empresa",
            key: "empresa",
        },
        {
            title: "Teléfono",
            dataIndex: "telefono",
            key: "telefono",
        },
        {
            title: "Correo electrónico",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Opciones",
            key: "opciones",
            width:'20%',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Button type="text" onClick={(e) => editModalHandle(record)}>
                            Editar
                        </Button>
                        <Popconfirm
                            title="Eliminando registro"
                            description="¿Está seguro de eliminar permanentemente este registro?"
                            onConfirm={(e) => {
                                deleteRegister(record.id);
                            }}
                            onCancel={() => { }}
                            okText="Sí, eliminar"
                            cancelText="Cancelar"
                        >
                            <Button type="text" danger>
                                Eliminar
                            </Button>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const editModalHandle = (event) => {
        setDirectorioDataToEdit(event);
        setIsModalOpen(true);
    };

    const newModalHandle = () => {
        let newUser = {

        }
        setDirectorioDataToEdit(newUser);
        setIsModalNewOpen(true);
    };

    const onChangeValue = (key,value) => {
        setDirectorioDataToEdit({ ...directorioDataToEdit, [key]: value.target.value });
        console.log(directorioDataToEdit);
    }
    const onChangeValueDate = (key,value) => {
        setDirectorioDataToEdit({ ...directorioDataToEdit, [key]: value });
        console.log(directorioDataToEdit);
    }


    useEffect(() => {
        getDirectorio();
    }, []);

    const getDirectorio = () => {
        let token = localStorage.getItem("token");
        setIsActiveLoader(true);
        axios.get(appConfig.API_URL + "api/app/get-directorio", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }).then((response) => {
                if (response.data.success) {
                    setDirectorioData(response.data.data);
                }
                setIsActiveLoader(false);
            });
    }

    const savedirectorioDataToEdit = () => {
        setIsModalOpen(false);
        let token = localStorage.getItem("token");
        axios.post(appConfig.API_URL + "api/app/update-directorio", directorioDataToEdit, {
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then((response) => {
            if (response.data.success) {
                message.info("Registro actualizado correctamente");
                getDirectorio();
            }
        });
    };

    const saveNewRegister = () => {
        setIsModalOpen(false);
        let token = localStorage.getItem("token");
        axios.post(appConfig.API_URL + "api/app/add-directorio", directorioDataToEdit, {
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then((response) => {
            if (response.data.success) {
                message.info("Registro guardado correctamente");
                getDirectorio();
            }
        });
    };

    const deleteRegister = (idRegistro) => {
        setIsModalOpen(false);
        let token = localStorage.getItem("token");
        axios.post(appConfig.API_URL + "api/app/delete-directorio", {
            id_registro: idRegistro
        },
        {
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then((response) => {
            if (response.data.success) {
                message.info("Registro eliminado correctamente");
                getDirectorio();
            }
        });
    };

    const parseDate = (date) => {
        dayjs.extend(customParseFormat);
        return dayjs(date);
    }

    return (
        <>
            <Header></Header>
            <div className="contenedor">
                <div className="eventosContainer">
                    <div className="eventHeader">
                        <div className="sectionTitle">Directorio</div>
                    </div>
                    <Space size="small">
                        <Button
                            type="primary"
                            icon={<UserAddOutlined />}
                            onClick={newModalHandle}
                            style={{ marginBottom: 10 }}
                        >
                            Nuevo registro
                        </Button>
                    </Space>
                    <Table columns={columns} size="small" sticky dataSource={directorioData} />
                </div>
            </div>
            <Modal
                title="Edición de usuario"
                open={isModalOpen}
                onOk={savedirectorioDataToEdit}
                onCancel={(e) => setIsModalOpen(false)}
                width={1000}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <div className="formContainer">
                    <Form layout="vertical">
                        <div className="row">
                            <div className="col-2">
                                <Form.Item label="Código">
                                    <Input placeholder="" onChange={(value) => {onChangeValue("codigo", value)}} value={directorioDataToEdit.codigo !== undefined ? directorioDataToEdit.codigo:""} />
                                </Form.Item>
                            </div>
                            <div className="col-2">
                                <Form.Item label="Referencia">
                                    <Input placeholder="" onChange={(value) => onChangeValue("referencia",value)} value={directorioDataToEdit.referencia !== undefined ? directorioDataToEdit.referencia:""} />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item label="Nombre">
                                    <Input placeholder="" onChange={(value) => onChangeValue("nombre",value)} value={directorioDataToEdit.nombre !== undefined ? directorioDataToEdit.nombre:""} />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item label="Apellidos">
                                    <Input placeholder="" onChange={(value) => onChangeValue("apellidos",value)} value={directorioDataToEdit.apellidos !== undefined ? directorioDataToEdit.apellidos:""} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <Form.Item label="Fecha Ingreso">
                                    <DatePicker onChange={(value, dateString) => onChangeValueDate("fecha_ingreso", dateString)} value={directorioDataToEdit.fecha_ingreso !== undefined ? parseDate(directorioDataToEdit.fecha_ingreso) : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-3">
                                <Form.Item label="Fecha de nacimiento">
                                    <DatePicker placeholder="Seleccione fecha" onChange={(value, dateString) => onChangeValueDate("fecha_nacimiento", dateString)} value={directorioDataToEdit.fecha_nacimiento !== undefined ? parseDate(directorioDataToEdit.fecha_nacimiento) : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item label="Empresa">
                                    <Input placeholder="" onChange={(value) => onChangeValue("empresa",value)} value={directorioDataToEdit.empresa !== undefined ? directorioDataToEdit.empresa:""} />
                                </Form.Item>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Form.Item label="Telefono">
                                    <Input placeholder="" onChange={(value) => onChangeValue("telefono",value)} value={directorioDataToEdit.telefono !== undefined ? directorioDataToEdit.telefono:""} />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item label="Correo electrónico">
                                    <Input placeholder="" onChange={(value) => onChangeValue("email",value)} value={directorioDataToEdit.email !== undefined ? directorioDataToEdit.email:""} />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item label="URL Sitio web">
                                    <Input placeholder="" onChange={(value) => onChangeValue("sitio_web",value)} value={directorioDataToEdit.sitio_web !== undefined ? directorioDataToEdit.sitio_web:""} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Form.Item label="Calle">
                                    <Input placeholder="" onChange={(value) => onChangeValue("calle",value)} value={directorioDataToEdit.calle !== undefined ? directorioDataToEdit.calle:""} />
                                </Form.Item>
                            </div>
                            <div className="col-2">
                                <Form.Item label="Numero exterior">
                                    <Input placeholder="" onChange={(value) => onChangeValue("numero_exterior",value)} value={directorioDataToEdit.numero_exterior !== undefined ? directorioDataToEdit.numero_exterior:""} />
                                </Form.Item>
                            </div>
                            <div className="col-2">
                                <Form.Item label="Numero interior">
                                    <Input placeholder="" onChange={(value) => onChangeValue("numero_interior",value)} value={directorioDataToEdit.numero_interior !== undefined ? directorioDataToEdit.numero_interior:""} />
                                </Form.Item>
                            </div>
                            <div className="col-2">
                                <Form.Item label="Codigo postal">
                                    <Input placeholder="" onChange={(value) => onChangeValue("codigo_postal",value)} value={directorioDataToEdit.codigo_postal !== undefined ? directorioDataToEdit.codigo_postal:""} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Item label="Colonia">
                                    <Input placeholder="" onChange={(value) => onChangeValue("colonia",value)} value={directorioDataToEdit.colonia !== undefined ? directorioDataToEdit.colonia:""} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item label="Alcaldía">
                                    <Input placeholder="" onChange={(value) => onChangeValue("alcaldia",value)} value={directorioDataToEdit.alcaldia !== undefined ? directorioDataToEdit.alcaldia:""} />
                                </Form.Item>
                            </div>

                        </div>
                    </Form>
                </div>
            </Modal>

            <Modal
                title="Nuevo Registro"
                open={isModalNewOpen}
                onOk={saveNewRegister}
                onCancel={(e) => setIsModalNewOpen(false)}
                width={1000}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <div className="formContainer">
                    <Form layout="vertical">
                        <div className="row">
                            <div className="col-2">
                                <Form.Item label="Código">
                                    <Input placeholder="" onChange={(value) => { onChangeValue("codigo", value) }} value={directorioDataToEdit.codigo !== undefined ? directorioDataToEdit.codigo : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-2">
                                <Form.Item label="Referencia">
                                    <Input placeholder="" onChange={(value) => {onChangeValue("referencia", value)}} value={directorioDataToEdit.referencia !== undefined ? directorioDataToEdit.referencia : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item label="Nombre">
                                    <Input placeholder="" onChange={(value) => onChangeValue("nombre", value)} value={directorioDataToEdit.nombre !== undefined ? directorioDataToEdit.nombre : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item label="Apellidos">
                                    <Input placeholder="" onChange={(value) => onChangeValue("apellidos", value)} value={directorioDataToEdit.apellidos !== undefined ? directorioDataToEdit.apellidos : ""} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <Form.Item label="Fecha Ingreso">
                                    <DatePicker onChange={(value, dateString) => onChangeValueDate("fecha_ingreso", dateString)} value={directorioDataToEdit.fecha_ingreso !== undefined ? parseDate(directorioDataToEdit.fecha_ingreso) : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-3">
                                <Form.Item label="Fecha de nacimiento">
                                    <DatePicker placeholder="Seleccione fecha" onChange={(value, dateString) => onChangeValueDate("fecha_nacimiento", dateString)} value={directorioDataToEdit.fecha_nacimiento !== undefined ? parseDate(directorioDataToEdit.fecha_nacimiento) : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item label="Empresa">
                                    <Input placeholder="" onChange={(value) => onChangeValue("empresa", value)} value={directorioDataToEdit.empresa !== undefined ? directorioDataToEdit.empresa : ""} />
                                </Form.Item>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Form.Item label="Telefono">
                                    <Input placeholder="" onChange={(value) => onChangeValue("telefono", value)} value={directorioDataToEdit.telefono !== undefined ? directorioDataToEdit.telefono : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item label="Correo electrónico">
                                    <Input placeholder="" onChange={(value) => onChangeValue("email", value)} value={directorioDataToEdit.email !== undefined ? directorioDataToEdit.email : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item label="URL Sitio web">
                                    <Input placeholder="" onChange={(value) => onChangeValue("sitio_web", value)} value={directorioDataToEdit.sitio_web !== undefined ? directorioDataToEdit.sitio_web : ""} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Form.Item label="Calle">
                                    <Input placeholder="" onChange={(value) => onChangeValue("calle", value)} value={directorioDataToEdit.calle !== undefined ? directorioDataToEdit.calle : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-2">
                                <Form.Item label="Numero exterior">
                                    <Input placeholder="" onChange={(value) => onChangeValue("numero_exterior", value)} value={directorioDataToEdit.numero_exterior !== undefined ? directorioDataToEdit.numero_exterior : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-2">
                                <Form.Item label="Numero interior">
                                    <Input placeholder="" onChange={(value) => onChangeValue("numero_interior", value)} value={directorioDataToEdit.numero_interior !== undefined ? directorioDataToEdit.numero_interior : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-2">
                                <Form.Item label="Codigo postal">
                                    <Input placeholder="" onChange={(value) => onChangeValue("codigo_postal", value)} value={directorioDataToEdit.codigo_postal !== undefined ? directorioDataToEdit.codigo_postal : ""} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Item label="Colonia">
                                    <Input placeholder="" onChange={(value) => onChangeValue("colonia", value)} value={directorioDataToEdit.colonia !== undefined ? directorioDataToEdit.colonia : ""} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item label="Alcaldía">
                                    <Input placeholder="" onChange={(value) => onChangeValue("alcaldia", value)} value={directorioDataToEdit.alcaldia !== undefined ? directorioDataToEdit.alcaldia : ""} />
                                </Form.Item>
                            </div>

                        </div>
                    </Form>
                </div>
            </Modal>
            <Loader isActive={isActiveLoader}></Loader>
        </>
    );
}

export default Directorio;
