
import "./general.css";
import Header from "../components/Header";

function Home() {
  return (
    <>
        <Header />
        <div className="contenedor">
            <div className="homeContainer">
                AMPI Ciudad de México
            </div>
        </div>
    </>
  );
}

export default Home;
