import axios from "axios";
import { useEffect, useState } from "react";
import appConfig from "../config.json";
import "../views/events.css";
import {
    Space,
    Table,
    Button,
    Modal,
    Input,
    Form,
    message,
    DatePicker,
    Popconfirm,
} from "antd";
import "../bootstrap-grid.css";
import { UserAddOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Header from "../components/Header";
import Loader from "../components/Loader";

function Patrocinadores() {
    const [patrocinadorData, setPatrocinadorData] = useState([]);
    const [isModalNewOpen, setIsModalNewOpen] = useState(false);
    const [patrocinadorToEdit, setPatricinadorToEdit] = useState(null);
    const [isActiveLoader, setIsActiveLoader] = useState(false);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Patrocinador",
            dataIndex: "nombre",
            key: "nombre",
        },
        {
            title: "Fecha de registro",
            dataIndex: "fecha_alta",
            key: "fecha_alta"
        },
        {
            title: "Fecha autoeliminación",
            dataIndex: "fecha_desactivacion",
            key: "fecha_desactivacion"
        },
        {
            title: "Opciones",
            key: "opciones",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Popconfirm
                            title="Eliminar patrocinador"
                            description="¿Está seguro de eliminar este patrocinador?"
                            onConfirm={(e) => {
                                deletePatrocinador(record.id, 0);
                            }}
                            onCancel={() => { }}
                            okText="Sí, eliminar"
                            cancelText="Cancelar"
                        >
                            <Button type="text" danger>
                                Eliminar
                            </Button>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const parseDate = (date) => {
        dayjs.extend(customParseFormat);
        return dayjs(date);
    }


    const newModalHandle = () => {
        let newRevista = {
            nombre: ""
        };
        setPatricinadorToEdit(newRevista);
        setIsModalNewOpen(true);
    };

    const onChangeDatePickerNew = (date, dateString) => {
        setPatricinadorToEdit({ ...patrocinadorToEdit, fecha_desactivacion: dateString });
    };

    const onChangeName = (event) => {
        setPatricinadorToEdit({ ...patrocinadorToEdit, nombre: event.target.value });
    };

    useEffect(() => {
        getPatrocinadores();
    }, []);

    const getPatrocinadores = () => {
        let token = localStorage.getItem("token");
        setIsActiveLoader(true);
        axios
            .get(appConfig.API_URL + "api/app/get-patrocinadores", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                if (response.data.success) {
                    setPatrocinadorData(response.data.data);
                }
                setIsActiveLoader(false);
            });
    };

    const deletePatrocinador = (id_patrocinador) => {
        try {
            setIsActiveLoader(true);
            let token = localStorage.getItem("token");
            axios
                .post(
                    appConfig.API_URL + "api/app/delete-patrocinador",
                    {
                        id_patrocinador: id_patrocinador
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        getPatrocinadores();
                        message.success("Revista eliminada correctamente");
                    }
                });
        } catch (error) {
            message.error("Ocurrió un error al intentar eliminar");
        }
    };

    const saveNewPatrocinador = () => {
        setIsActiveLoader(true);
        let token = localStorage.getItem("token");
        axios
            .post(appConfig.API_URL + "api/app/add-patrocinador", patrocinadorToEdit, {
                headers: {
                    Authorization: "Bearer " + token,
                },
                onUploadProgress: function (progress) {
                    console.log("progressObj", progress);
                }
            })
            .then((response) => {
                if (response.data.success) {
                    //setEventsData(response.data.data);
                    message.success("Revista guardada correctamente");
                    getPatrocinadores();
                    setIsModalNewOpen(false);
                    setIsActiveLoader(false);
                }
            });
    };

    const onChangeNewImagePatrocinador = (event) => {

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);

        reader.onload = (e) => {
            console.log(e);
            if (e.total > 2097152) {
                message.error("La imagen seleccionada supera 1 MB de tamaño");
            } else {
                let sbstr = e.target.result.substring(11, 15);

                if (sbstr.includes("png")) {
                    setPatricinadorToEdit({
                        ...patrocinadorToEdit,
                        imagen: e.target.result,
                    });
                } else {
                    message.error("Formato de archivo no admitido...");
                }
            }
        };

    };

    return (
        <>
            <Header></Header>
            <div className="contenedor">
                <div className="eventosContainer">
                    <div className="eventHeader">
                        <div className="sectionTitle">Revistas</div>
                    </div>
                    <Space size="small">
                        <Button
                            type="primary"
                            icon={<UserAddOutlined />}
                            onClick={newModalHandle}
                            style={{ marginBottom: 10 }}
                        >
                            Registrar patrocinador
                        </Button>
                    </Space>
                    <Table
                        columns={columns}
                        size="small"
                        sticky
                        dataSource={patrocinadorData}
                    />
                </div>
            </div>

            <Modal
                title="Nueva publicación"
                open={isModalNewOpen}
                onOk={saveNewPatrocinador}
                onCancel={(e) => setIsModalNewOpen(false)}
                width={1000}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <div className="formContainer">
                    <Form layout="vertical">
                        <div className="row">
                            <div className="col-6">
                                <Form.Item label="Patrocinador">
                                    <Input
                                        placeholder=""
                                        onChange={onChangeName}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item label="Fecha de autodesactivación">
                                    <DatePicker
                                        onChange={onChangeDatePickerNew}
                                        // value={
                                        //     patrocinadorToEdit != null
                                        //         ? parseDate(patrocinadorToEdit.fecha_desactivacion)
                                        //         : null
                                        // }
                                    />
                                    <small>Ignorar si es permanente</small>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6" style={{ display: "flex", flexDirection: "row" }}>
                                <Form.Item label="Imagen patrocinador">
                                    <input
                                        style={{ width: "135px" }}
                                        type="file"
                                        onChange={onChangeNewImagePatrocinador}
                                        accept="image/jpg"
                                    ></input>
                                </Form.Item>
                                <div>
                                    <img
                                        src={
                                            patrocinadorToEdit?.imagen != null
                                                ? patrocinadorToEdit.imagen
                                                : ""
                                        }
                                        alt="Selected IMG"
                                        height={80}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Loader isActive={isActiveLoader}></Loader>
        </>
    );
}

export default Patrocinadores;
