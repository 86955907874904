import axios from "axios";
import { useEffect, useState, useRef } from "react";
import appConfig from "../config.json";
import '../views/events.css';
import { Space, Table, Button, Modal, Input, Form, DatePicker, TimePicker , message, Popconfirm} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import dayjs from "dayjs";
import customParseFormat  from 'dayjs/plugin/customParseFormat';
import '../bootstrap-grid.css';
import Header from "../components/Header";
import Loader from "../components/Loader";

function Events() {

    const [eventsData, setEventsData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [eventToEdit, setEventToEdit] = useState(null);
    const [isActiveLoader, setIsActiveLoader] = useState(false);

    const [isModalNewEventOpen, setIsModalNewEventOpen] = useState(false);
    const [eventToRegister, setEventToRegister] = useState(null);

    const editorRef = useRef(null);
    const editorNewRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        //console.log(editorRef.current.getContent());
        setEventToEdit({
          ...eventToEdit,
          contenido: editorRef.current.getContent(),
        });
      }
    };

    const logNew = () => {
      if (editorNewRef.current) {
        //console.log(editorRef.current.getContent());
        setEventToRegister({
          ...eventToRegister,
          contenido: editorNewRef.current.getContent(),
        });
      }
    };

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Título",
        dataIndex: "titulo",
        key: "titulo",
      },
      {
        title: "Ponente",
        dataIndex: "impartido_por",
        key: "impartido_por",
      },
      {
        title: "Fecha de evento",
        dataIndex: "fecha_evento",
        key: "fecha_evento",
      },
      {
        title: "Hora de evento",
        dataIndex: "hora_evento",
        key: "hora_evento",
      },
      {
        title: "Dirección",
        dataIndex: "direccion",
        key: "direccion",
      },
      {
        title: "Opciones",
        key: "opciones",
        render: (_, record) => (
          <Space size="middle">
            <Button type="text" onClick={(e) => editModalHandle(record)}>
              Editar
            </Button>
            {record.activo === 1 ? (
              <Popconfirm
                title="Desactivar evento"
                description="¿Está seguro de desactivar a este evento?"
                onConfirm={(e) => {
                  disableEvent(record.id);
                }}
                onCancel={() => {}}
                okText="Sí, desactivar"
                cancelText="Cancelar"
              >
                <Button type="text" danger>
                  Desactivar
                </Button>
              </Popconfirm>
            ) : (
              <Button
                onClick={(e) => {
                  enableEvent(record.id);
                }}
                type="text"
              >
                Activar
              </Button>
            )}
          </Space>
        ),
      },
    ];

    const editModalHandle = (event) => {
        
        setEventToEdit(event);
        setIsModalOpen(true);
    }
    
    const onChangeDatePicker = (date, dateString) => {
        setEventToEdit({ ...eventToEdit, fecha_evento: dateString });
    }
    const onChangeTimePicker = (time, timeString) => {
        let timeFormated = time.format("HH:mm:ss");
        console.log(timeFormated);
      setEventToEdit({ ...eventToEdit, hora_evento: timeFormated });
    };

    const onChangeTitle = (event) => {
        setEventToEdit({ ...eventToEdit, titulo: event.target.value });
    }

    const onChangePonente = (event) => {
      setEventToEdit({ ...eventToEdit, impartido_por: event.target.value });
    };
    const onChangeDireccion = (event) => {
      setEventToEdit({ ...eventToEdit, direccion: event.target.value });
    };
    const onChangeCoordenadas = (event) => {
      setEventToEdit({ ...eventToEdit, coordenadas: event.target.value });
    };
    
    const onChangeNewImage = (event) => {

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        
        reader.onload = (e) => {
            console.log(e);
            if ((e.total > 2097152)){
                message.error("La imagen seleccionada supera los 2 MB de tamaño");
            }else{
                let sbstr = e.target.result.substring(11,15);
                if (sbstr.includes("jpeg") || sbstr.includes("png")){
                    setEventToEdit({
                      ...eventToEdit,
                      new_image: e.target.result,
                    });
                }else{
                    message.error(
                      "Formato de archivo no admitido"
                    );
                }
                  
            }
        };
        
    };

    



    const onChangeDatePickerNew = (date, dateString) => {
      setEventToRegister({ ...eventToRegister, fecha_evento: dateString });
    };
    const onChangeTimePickerNew = (time, timeString) => {
      let timeFormated = time.format("HH:mm:ss");
      console.log(timeFormated);
      setEventToRegister({ ...eventToRegister, hora_evento: timeFormated });
    };

    const onChangeTitleNew = (event) => {
      setEventToRegister({ ...eventToRegister, titulo: event.target.value });
    };

    const onChangePonenteNew = (event) => {
      setEventToRegister({ ...eventToRegister, impartido_por: event.target.value });
    };
    const onChangeDireccionNew = (event) => {
      setEventToRegister({ ...eventToRegister, direccion: event.target.value });
    };
    const onChangeCoordenadasNew = (event) => {
      setEventToRegister({ ...eventToRegister, coordenadas: event.target.value });
    };

    const onChangeNewImageNew = (event) => {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (e) => {
        console.log(e);
        if (e.total > 2097152) {
          message.error("La imagen seleccionada supera los 2 MB de tamaño");
        } else {
          let sbstr = e.target.result.substring(11, 15);
          if (sbstr.includes("jpeg") || sbstr.includes("png")) {
            setEventToRegister({
              ...eventToRegister,
              new_image: e.target.result,
            });
          } else {
            message.error("Formato de archivo no admitido");
          }
        }
      };
    };




    const parseDate = (date) => {
        dayjs.extend(customParseFormat);
        return dayjs(date);
    }
    
    const refreshEvents = () => {
        let token = localStorage.getItem("token");
        setIsActiveLoader(true);
        axios
          .get(appConfig.API_URL + "api/app/get-events", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              setEventsData(response.data.data);
            }
            setIsActiveLoader(false);
          });
    }

    useEffect(() => {
        refreshEvents();
    }, []);

    const saveEventToEdit = () => {
        setIsModalOpen(false);
        console.log(eventToEdit);
        let token = localStorage.getItem("token");
        axios
          .post(appConfig.API_URL + "api/app/update-events", eventToEdit, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              //setEventsData(response.data.data);
              message.info("Evento actualizado correctamente");
              refreshEvents();
            }
          });
    }

    const saveEventToRegister= () => {
      setIsModalNewEventOpen(false);
      let token = localStorage.getItem("token");
      axios
        .post(appConfig.API_URL + "api/app/register-event", eventToRegister, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.data.success) {
            //setEventsData(response.data.data);
            message.info("Evento registrado correctamente");
            refreshEvents();
          }
        });
    };

    const disableEvent = (event_id) => {
        let token = localStorage.getItem("token");
        axios
          .post(
            appConfig.API_URL + "api/app/disable-event",
            {
              event_id: event_id,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              //setEventsData(response.data.data);
              refreshEvents();
              message.info("Evento deshabilitado correctamente");
            }
          });
    }
    const enableEvent = (event_id) => {
        let token = localStorage.getItem("token");
      axios
        .post(
          appConfig.API_URL + "api/app/enable-event",
          {
            event_id: event_id,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            //setEventsData(response.data.data);
            message.info("Evento habilitado correctamente");
            refreshEvents();
          }
        });
    };

    return (
      <>
        <Header />
        <div className="contenedor">
          <div className="eventosContainer">
            <div className="eventHeader">
              <div className="sectionTitle">Eventos</div>
            </div>
            <Space wrap style={{ marginTop: 5, marginBottom: 10 }}>
              <Button
                onClick={(e) => setIsModalNewEventOpen(true)}
                type="primary"
              >
                Nuevo evento
              </Button>
            </Space>

            <Table
              columns={columns}
              size="small"
              sticky
              dataSource={eventsData}
            />
          </div>
        </div>
        <Modal
          title="Edición de evento"
          open={isModalOpen}
          onOk={saveEventToEdit}
          onCancel={(e) => setIsModalOpen(false)}
          width={1000}
          okText="Guardar"
          cancelText="Cancelar"
        >
          <div className="formContainer">
            <Form layout="vertical">
              <div className="row">
                <div className="col-12">
                  <Form.Item label="Titulo del evento">
                    <Input
                      placeholder=""
                      onChange={onChangeTitle}
                      value={eventToEdit != null ? eventToEdit.titulo : ""}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Form.Item label="Ponente">
                    <Input
                      placeholder=""
                      onChange={onChangePonente}
                      value={
                        eventToEdit != null ? eventToEdit.impartido_por : ""
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item label="Sede">
                    <Input
                      placeholder=""
                      onChange={onChangeDireccion}
                      value={eventToEdit != null ? eventToEdit.direccion : ""}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-6"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Form.Item label="Imagen de evento">
                    <input
                      style={{ width: "135px" }}
                      type="file"
                      onChange={onChangeNewImage}
                      accept="image/png, image/jpeg"
                    ></input>
                  </Form.Item>
                  <div>
                    <img
                      src={
                        eventToEdit?.new_image != null
                          ? eventToEdit.new_image
                          : ""
                      }
                      alt="Selected IMG"
                      height={80}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <Form.Item label="Coordenadas (Omitir si es vía Zoom)">
                    <Input
                      placeholder=""
                      onChange={onChangeCoordenadas}
                      value={eventToEdit != null ? eventToEdit.coordenadas : ""}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Form.Item label="Fecha de evento">
                    <DatePicker
                      onChange={onChangeDatePicker}
                      value={
                        eventToEdit != null
                          ? parseDate(eventToEdit.fecha_evento)
                          : null
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item label="Horario de inicio">
                    <TimePicker
                      use12Hours
                      onChange={onChangeTimePicker}
                      format="hh:mm a"
                      minuteStep={15}
                      defaultValue={
                        eventToEdit != null
                          ? dayjs(eventToEdit.hora_evento, "HH:mm:ss")
                          : null
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={
                      eventToEdit != null ? eventToEdit.contenido : ""
                    }
                    apiKey="2mawwyu4pwia9aks0srdw8szqj1mzhzsgj0yi4rs11wt3xef"
                    onChange={log}
                    plugins="advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount"
                    toolbar="undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help"
                    init={{
                      height: 400,
                      menubar: true,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | link | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Registrar nuevo evento"
          open={isModalNewEventOpen}
          onOk={saveEventToRegister}
          onCancel={(e) => setIsModalNewEventOpen(false)}
          width={1000}
          okText="Guardar"
          cancelText="Cancelar"
        >
          <div className="formContainer">
            <Form layout="vertical">
              <div className="row">
                <div className="col-12">
                  <Form.Item label="Titulo del evento">
                    <Input
                      placeholder=""
                      onChange={onChangeTitleNew}
                      value={
                        eventToRegister != null ? eventToRegister.titulo : ""
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Form.Item label="Ponente">
                    <Input
                      placeholder=""
                      onChange={onChangePonenteNew}
                      value={
                        eventToRegister != null
                          ? eventToRegister.impartido_por
                          : ""
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item label="Sede">
                    <Input
                      placeholder=""
                      onChange={onChangeDireccionNew}
                      value={
                        eventToRegister != null ? eventToRegister.direccion : ""
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-6"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Form.Item label="Imagen de evento">
                    <input
                      style={{ width: "135px" }}
                      type="file"
                      onChange={onChangeNewImageNew}
                      accept="image/png, image/jpeg"
                    ></input>
                  </Form.Item>
                  <div>
                    <img
                      src={
                        eventToRegister?.new_image != null
                          ? eventToRegister.new_image
                          : ""
                      }
                      alt="Selected IMG"
                      height={80}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <Form.Item label="Coordenadas (Omitir si es vía Zoom)">
                    <Input
                      placeholder=""
                      onChange={onChangeCoordenadasNew}
                      value={
                        eventToRegister != null
                          ? eventToRegister.coordenadas
                          : ""
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Form.Item label="Fecha de evento">
                    <DatePicker
                      onChange={onChangeDatePickerNew}
                      value={
                        eventToRegister != null
                          ? parseDate(eventToRegister.fecha_evento)
                          : null
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item label="Horario de inicio">
                    <TimePicker
                      use12Hours
                      onChange={onChangeTimePickerNew}
                      format="hh:mm a"
                      minuteStep={15}
                      defaultValue={
                        eventToRegister != null
                          ? dayjs(eventToRegister.hora_evento, "HH:mm:ss")
                          : null
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Editor
                    onInit={(evt, editor) => (editorNewRef.current = editor)}
                    initialValue={
                      eventToRegister != null ? eventToRegister.contenido : ""
                    }
                    apiKey="2mawwyu4pwia9aks0srdw8szqj1mzhzsgj0yi4rs11wt3xef"
                    onChange={logNew}
                    plugins="advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount"
                    toolbar="undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help"
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>
            </Form>
          </div>
        </Modal>
        <Loader isActive={isActiveLoader}></Loader>
      </>
    );
}

export default Events;
