import axios from "axios";
import { useEffect, useState } from "react";
import appConfig from "../config.json";
import "../views/events.css";
import {
  Space,
  Table,
  Button,
  Modal,
  Input,
  Form,
  message,
  Select,
  Popconfirm,
} from "antd";
import "../bootstrap-grid.css";
import { UserAddOutlined } from "@ant-design/icons";
import Header from "../components/Header";
import Loader from "../components/Loader";

function Users() {
  const [usersData, setUsersData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNewOpen, setIsModalNewOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [isActiveLoader, setIsActiveLoader] = useState(false);


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Perfil",
      dataIndex: "rol",
      key: "rol",
    },
    {
      title: "Fecha de registro",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Opciones",
      key: "opciones",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button type="text" onClick={(e) => editModalHandle(record)}>
              Editar
            </Button>
            {record.activo === 1 ? (
              <Popconfirm
                title="Desactivando usuario"
                description="¿Está seguro de desactivar a este usuario?"
                onConfirm={(e) => {
                  updateActiveUser(record.id, 0);
                }}
                onCancel={() => {}}
                okText="Sí, desactivar"
                cancelText="Cancelar"
              >
                <Button type="text" danger>
                  Desactivar
                </Button>
              </Popconfirm>
            ) : (
              <Button
                onClick={(e) => {
                  updateActiveUser(record.id, 1);
                }}
                type="text"
              >
                Activar
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const editModalHandle = (event) => {
    setUserToEdit(event);
    setIsModalOpen(true);
  };

  const newModalHandle = () => {
    let newUser = {
        name:"",
        email:"",
        password:""
    }
    setUserToEdit(newUser);
    setIsModalNewOpen(true);
  };


  const onChangeName = (event) => {
    setUserToEdit({ ...userToEdit, name: event.target.value });
  };

  const onChangeEmail = (event) => {
    setUserToEdit({ ...userToEdit, email: event.target.value });
  };
  const onChangePassword = (event) => {
    setUserToEdit({ ...userToEdit, password: event.target.value });
  };
  const onChangeRol = (value) => {
    setUserToEdit({ ...userToEdit, id_rol: value });
  };


  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    let token = localStorage.getItem("token");
    setIsActiveLoader(true);
    axios
      .get(appConfig.API_URL + "api/app/get-users", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setUsersData(response.data.data);
        }
        setIsActiveLoader(false);
      });
    axios
      .get(appConfig.API_URL + "api/app/get-roles", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setRolesData(response.data.data);
        }
      });
  }

  const updateActiveUser = (id_user, activeValue) => {
    try {
        let token = localStorage.getItem("token");
        axios
          .post(
            appConfig.API_URL + "api/app/update-user-active",
            {
              id_user: id_user,
              active_value: activeValue,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              //setEventsData(response.data.data);
              message.info("Usuario actualizado correctamente");
              getUsers();
            }
          });
    } catch (error) {
        
    }
  }


  const saveuserToEdit = () => {
    setIsModalOpen(false);
    let token = localStorage.getItem("token");
    axios
      .post(appConfig.API_URL + "api/app/update-user", userToEdit, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          //setEventsData(response.data.data);
          message.info("Usuario actualizado correctamente");
          getUsers();
        }
      });
  };

  const saveNewUser = () => {
    setIsModalOpen(false);
    let token = localStorage.getItem("token");
    axios
      .post(appConfig.API_URL + "api/app/add-user", userToEdit,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          //setEventsData(response.data.data);
          message.info("Usuario actualizado correctamente");
          getUsers();
        }
      });
  };

  return (
    <>
      <Header></Header>
      <div className="contenedor">
        <div className="eventosContainer">
          <div className="eventHeader">
            <div className="sectionTitle">Usuarios</div>
          </div>
          <Space size="small">
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              onClick={newModalHandle}
              style={{ marginBottom: 10 }}
            >
              Agregar usuario
            </Button>
          </Space>
          <Table columns={columns} size="small" sticky dataSource={usersData} />
        </div>
      </div>
      <Modal
        title="Edición de usuario"
        open={isModalOpen}
        onOk={saveuserToEdit}
        onCancel={(e) => setIsModalOpen(false)}
        width={1000}
        okText="Guardar"
        cancelText="Cancelar"
      >
        <div className="formContainer">
          <Form layout="vertical">
            <div className="row">
              <div className="col-6">
                <Form.Item label="Usuario">
                  <Input
                    placeholder=""
                    onChange={onChangeName}
                    value={userToEdit != null ? userToEdit.name : ""}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item label="Correo electrónico">
                  <Input
                    placeholder=""
                    onChange={onChangeEmail}
                    value={userToEdit != null ? userToEdit.email : ""}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Form.Item label="Contraseña">
                  <Input
                    placeholder=""
                    type="password"
                    onChange={onChangePassword}
                    value={userToEdit != null ? userToEdit.password : ""}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item label="Perfil">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={userToEdit != null ? userToEdit.id_rol : ""}
                    onChange={onChangeRol}
                    options={rolesData}
                    fieldNames={{
                      label: "nombre",
                      value: "id_rol",
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Nuevo usuario"
        open={isModalNewOpen}
        onOk={saveNewUser}
        onCancel={(e) => setIsModalNewOpen(false)}
        width={1000}
        okText="Guardar"
        cancelText="Cancelar"
      >
        <div className="formContainer">
          <Form layout="vertical">
            <div className="row">
              <div className="col-6">
                <Form.Item label="Usuario">
                  <Input
                    placeholder=""
                    onChange={onChangeName}
                    value={userToEdit != null ? userToEdit.name : ""}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item label="Correo electrónico">
                  <Input
                    placeholder=""
                    onChange={onChangeEmail}
                    value={userToEdit != null ? userToEdit.email : ""}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Form.Item label="Contraseña">
                  <Input
                    placeholder=""
                    type="password"
                    onChange={onChangePassword}
                    value={userToEdit != null ? userToEdit.password : ""}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item label="Perfil">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    onChange={onChangeRol}
                    options={rolesData}
                    fieldNames={{
                      label: "nombre",
                      value: "id_rol",
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <Loader isActive={isActiveLoader}></Loader>
    </>
  );
}

export default Users;
