import "./loader.css";

const Loader = (props) => {
  const { isActive } = props;
  return (
    <>
      {isActive ? (
        <div className="loader">
          <img src="/loader.svg"></img>
          Cargando...
        </div>
      ) : null}
    </>
  );
};

export default Loader;
