import axios from "axios";
import { useEffect, useState } from "react";
import appConfig from "../config.json";
import "./login.css";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function Login() {
    let navigate = useNavigate();
    const [isActiveLoader, setIsActiveLoader] = useState(false);
    const onFinish = (values) => {
        setIsActiveLoader(true);
        axios
            .post(appConfig.API_URL + "api/app/login", values)
            .then((response) => {
                if (response.data.success) {
                    localStorage.setItem("token", response.data.access_token);
                    navigate("/eventos");
                }
                setIsActiveLoader(false);
            })
            .catch(() => {
                message.error("Correo electrónico o contraseña inválidos");
                setIsActiveLoader(false);
                localStorage.clear();
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <>
            <div className="contenedorLogin">

                <div className="form">
                    <div className="imgContainer">
                        <img src="/logo_ampicdmx.png" />
                    </div>
                    <div className="moduleTitleContainer">
                        Módulo administración de contenido para sitio web AMPI Ciudad de México.<br/><br/>
                        Para iniciar sesión ingrese sus credenciales previamente creadas. Si no tiene credenciales de acceso por favor solicítelas al área correspondiente.
                    </div>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Correo electrónico"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, ingrese un correo electrónico",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Contraseña"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, ingrese una contraseña válida",
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,

                                //span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Iniciar sesión
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Loader isActive={isActiveLoader}></Loader>
        </>
    );
}

export default Login;
