import Home from "./views/Home";
import Eventos from "./views/Events";
import Users from "./views/Users";
import Certificates from "./views/Certificates";
import Revistas from "./views/Revistas";
import Patrocinadores from "./views/Patrocinadores";
import Directorio from "./views/Directorio";
import Consejo from "./views/Consejo";
import Login from "./views/Login";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          
          <Route path="/" element={<Home />}></Route>
          <Route path="/eventos" element={<Eventos />}></Route>
          <Route path="/usuarios" element={<Users />}></Route>
          <Route path="/certificados" element={<Certificates />}></Route>
          <Route path="/revistas" element={<Revistas />}></Route>
          <Route path="/patrocinadores" element={<Patrocinadores />}></Route>
          <Route path="/directorio" element={<Directorio />}></Route>
          <Route path="/consejo" element={<Consejo />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
