import axios from "axios";
import { useEffect, useState } from "react";
import appConfig from "../config.json";
import "../views/events.css";
import {
  Space,
  Table,
  Button,
  Modal,
  Input,
  Form,
  message,
  Select,
  Popconfirm,
} from "antd";
import "../bootstrap-grid.css";
import { UserAddOutlined } from "@ant-design/icons";
import Header from "../components/Header";
import Loader from "../components/Loader";

function Revistas() {
  const [revistasData, setRevistasData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNewOpen, setIsModalNewOpen] = useState(false);
  const [revistaToEdit, setRevistaToEdit] = useState(null);
  const [isActiveLoader, setIsActiveLoader] = useState(false);

  const mesesData = [{ id: 1 }];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Titulo",
      dataIndex: "titulo",
      key: "titulo",
    },
    {
      title: "Mes",
      dataIndex: "mes",
      key: "mes",
      render: (_, record) => {
        let meses = [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre",
        ];
        let mes = meses[record.mes - 1];
        return <>{mes}</>;
      },
    },
    {
      title: "Año",
      dataIndex: "anio",
      key: "anio",
    },
    {
      title: "Fecha de alta",
      dataIndex: "fecha_registro",
      key: "fecha_registro",
    },

    {
      title: "Opciones",
      key: "opciones",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Eliminar revista"
              description="¿Está seguro de eliminar esta revista?"
              onConfirm={(e) => {
                deleteRevista(record.id, 0);
              }}
              onCancel={() => {}}
              okText="Sí, eliminar"
              cancelText="Cancelar"
            >
              <Button type="text" danger>
                Eliminar
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const editModalHandle = (event) => {
    setRevistaToEdit(event);
    setIsModalOpen(true);
  };

  const newModalHandle = () => {
    let newRevista = {
      titulo: ""
    };
    setRevistaToEdit(newRevista);
    setIsModalNewOpen(true);
  };

  const onChangeTitulo = (event) => {
    setRevistaToEdit({ ...revistaToEdit, titulo: event.target.value });
  };

  const onChangeAnio = (event) => {
    setRevistaToEdit({ ...revistaToEdit, anio: event.target.value });
  };

  const onChangeMes = (value) => {
    setRevistaToEdit({ ...revistaToEdit, mes: value });
  };

  useEffect(() => {
    getRevistas();
  }, []);

  const getRevistas = () => {
    let token = localStorage.getItem("token");
    setIsActiveLoader(true);
    axios
      .get(appConfig.API_URL + "api/app/get-revistas", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setRevistasData(response.data.data);
        }
        setIsActiveLoader(false);
      });
  };

  const deleteRevista = (id_revista) => {
    try {
        setIsActiveLoader(true);
      let token = localStorage.getItem("token");
      axios
        .post(
          appConfig.API_URL + "api/app/delete-revista",
          {
            id_revista: id_revista
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            getRevistas();
            message.success("Revista eliminada correctamente");
          }
        });
    } catch (error) {
        message.error("Ocurrió un error al intentar eliminar");
    }
  };

  const saveNewRevista = () => {
    setIsActiveLoader(true);
    let token = localStorage.getItem("token");
    axios
      .post(appConfig.API_URL + "api/app/add-revista", revistaToEdit, {
        headers: {
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function(progress){
            console.log("progressObj",progress);
        }
      })
      .then((response) => {
        if (response.data.success) {
          //setEventsData(response.data.data);
          message.success("Revista guardada correctamente");
          getRevistas();
          setIsModalNewOpen(false);
          setIsActiveLoader(false);
        }
      });
  };

  const onChangeNewImagePortada = (event) => {
    
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (e) => {
      console.log(e);
      if (e.total > 2097152) {
        message.error("La imagen seleccionada supera los 2 MB de tamaño");
      } else {
        let sbstr = e.target.result.substring(11, 15);
        
        if (sbstr.includes("png") || sbstr.includes("jpg") || sbstr.includes("jpeg")) {
          setRevistaToEdit({
            ...revistaToEdit,
            portada_revista: e.target.result,
          });
        } else {
          message.error("Formato de archivo no admitido...");
        }
      }
    };
    
  };
  const onChangeNewRevistaFile = (event) => {
    setIsModalOpen(true);
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (e) => {
      console.log(e);
      
        let sbstr = e.target.result.substring(0, 45);
        console.log("portada64", sbstr);
        if (sbstr.includes("application/pdf")) {
          setRevistaToEdit({
            ...revistaToEdit,
            archivo_revista: e.target.result,
          });
        } else {
          message.error("Formato de archivo no admitido");
        }
      
    };
    setIsModalOpen(false);
  };

  return (
    <>
      <Header></Header>
      <div className="contenedor">
        <div className="eventosContainer">
          <div className="eventHeader">
            <div className="sectionTitle">Revistas</div>
          </div>
          <Space size="small">
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              onClick={newModalHandle}
              style={{ marginBottom: 10 }}
            >
              Nueva publicación
            </Button>
          </Space>
          <Table
            columns={columns}
            size="small"
            sticky
            dataSource={revistasData}
          />
        </div>
      </div>

      <Modal
        title="Nueva publicación"
        open={isModalNewOpen}
        onOk={saveNewRevista}
        onCancel={(e) => setIsModalNewOpen(false)}
        width={1000}
        okText="Guardar"
        cancelText="Cancelar"
      >
        <div className="formContainer">
          <Form layout="vertical">
            <div className="row">
              <div className="col-4">
                <Form.Item label="Titulo">
                  <Input
                    placeholder=""
                    onChange={onChangeTitulo}
                    value={revistaToEdit != null ? revistaToEdit.name : ""}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Mes">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={revistaToEdit != null ? revistaToEdit.mes : ""}
                    onChange={onChangeMes}
                    options={[
                        { value:1, label:"enero" },
                        { value:2, label:"febrero" },
                        { value:3, label:"marzo" },
                        { value:4, label:"abril" },
                        { value:5, label:"mayo" },
                        { value:6, label:"junio" },
                        { value:7, label:"julio" },
                        { value:8, label:"agosto" },
                        { value:9, label:"septiembre" },
                        { value:10, label:"octubre" },
                        { value:11, label:"noviembre" },
                        { value:12, label:"diciembre" },
                    ]}
                    
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Año">
                  <Input
                    placeholder=""
                    onChange={onChangeAnio}
                    value={revistaToEdit != null ? revistaToEdit.email : ""}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div
                className="col-6"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Form.Item label="Portada de revista">
                  <input
                    style={{ width: "135px" }}
                    type="file"
                    onChange={onChangeNewImagePortada}
                    accept="image/jpg"
                  ></input>
                </Form.Item>
                <div>
                  <img
                    src={
                      revistaToEdit?.portada_revista != null
                        ? revistaToEdit.portada_revista
                        : ""
                    }
                    alt="Selected IMG"
                    height={80}
                  />
                </div>
              </div>
              <div
                className="col-6"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Form.Item label="Revista (PDF)">
                  <input
                    style={{ width: "135px" }}
                    type="file"
                    onChange={onChangeNewRevistaFile}
                    accept="document/pdf"
                  ></input>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <Loader isActive={isActiveLoader}></Loader>
    </>
  );
}

export default Revistas;
