import './Header.css';
import * as React from 'react';
import {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Icon } from '@iconify/react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '@mui/material/Avatar';
import UsuariosIcon from '@mui/icons-material/People';
import RevistasIcon from '@mui/icons-material/MenuBook';
import PatrocinadoresIcon from '@mui/icons-material/Workspaces';
//import BlogIcon from '@mui/icons-material/RssFeed';
import DirectorioIcon from '@mui/icons-material/SupervisedUserCircle';

import EventIcon from '@mui/icons-material/Event';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import appConfig from "../config.json";


function Header() {
    const navigate = useNavigate();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 10 }}>
        <Avatar sx={{ bgcolor: "#28b24b" }}>SU</Avatar>
      </div>

      <Divider />
      <List>
        <ListItem
          onClick={() => navigate("/eventos")}
          key="eventos"
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Eventos" />
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => navigate("/usuarios")}
          key="usuarios"
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <UsuariosIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => navigate("/certificados")}
          key="certificados"
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <DirectorioIcon />
            </ListItemIcon>
            <ListItemText primary="Certificados" />
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => navigate("/revistas")}
          key="revistas"
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <RevistasIcon />
            </ListItemIcon>
            <ListItemText primary="Revistas" />
          </ListItemButton>
        </ListItem>
   
        <ListItem onClick={() => navigate("/patrocinadores")} key="eventos" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PatrocinadoresIcon />
            </ListItemIcon>
            <ListItemText primary="Patrocinadores" />
          </ListItemButton>
        </ListItem>
        {/* <ListItem onClick={() => navigate("/blog")} key="eventos" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <BlogIcon />
            </ListItemIcon>
            <ListItemText primary="Blog" />
          </ListItemButton>
        </ListItem> */}
        <ListItem onClick={() => navigate("/directorio")} key="eventos" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DirectorioIcon />
            </ListItemIcon>
            <ListItemText primary="Directorio" />
          </ListItemButton>
        </ListItem>
        <ListItem onClick={() => navigate("/consejo")} key="eventos" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DirectorioIcon />
            </ListItemIcon>
            <ListItemText primary="Consejo 2023" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem onClick={() => navigate("/datos")} key="eventos" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DirectorioIcon />
            </ListItemIcon>
            <ListItemText primary="Datos de contacto" />
          </ListItemButton>
        </ListItem>  */}
      </List>
    </Box>
  );

  const checkAuth = () => {
        let token = localStorage.getItem("token");
        if (token != null) {
        axios
            .post(
            appConfig.API_URL + "api/app/profile",
            {},
            {
                headers: {
                Authorization: "Bearer " + token,
                },
            }
            )
            .then((response) => {
            if (response.data.success) {
            }
            })
            .catch(() => {
            localStorage.removeItem("token");
            navigate("/login");
            });
        }else{
            navigate("/login");
        }
    setInterval(() => {
        let token = localStorage.getItem("token");
        if(token != null){
            axios
              .post(
                appConfig.API_URL + "api/app/profile",
                {},
                {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                }
              )
              .then((response) => {
                if (response.data.success) {
                }
              })
              .catch(() => {
                localStorage.removeItem("token");
                navigate("/login");
              });
        }else{
            navigate("/login");
        }
    }, 120000);
    
  };

  useEffect(() => {
    checkAuth();
  },[]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      <div className='headerComponent'>
        <div className='burgerIcon'><Icon icon="ci:hamburger" onClick={toggleDrawer('left', true)} /></div>
        <div className='appName'>Administración AMPI Ciudad de México</div>
        <div className='menu'></div>
        <div className='userInfo'>
        </div>
      </div>
      <React.Fragment>
          <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
          >
            {list('left')}
          </Drawer>
        </React.Fragment>
    </>
    
  );
}

export default Header;
